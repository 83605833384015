import React from 'react'
import './projects.css'

const Projects = () => {
    return (
        <section id='projects'>

        </section>
    )
}

export default Projects