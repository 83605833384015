export const volunteering_positions = [
    {
        title: 'Co-chair & Tech Lead',
        date: 'Oct. 2023 – Present',
        company: 'Ctrl+Hack+Delete',
        location: 'Toronto, ON',
        description: [" Spearheaded the establishment of the Ctrl+Hack+Delete hackathon at York University, pioneering a new annual event aimed at fostering innovation and collaboration within the university’s tech community and beyond", "Conducted comprehensive interviews to assemble a highly skilled and diverse tech team, ensuring a well-rounded set of skills to tackle the diverse challenges presented during the hackathon"]
    },
    {
        title: 'IT Executive',
        date: 'Oct. 2022 – Feb. 2023',
        company: 'ElleHacks',
        location: 'Toronto, ON',
        description: ["Used Figma to design web site mock-ups for ElleHacks 2023", "Developed the ElleHacks 2023 website using HTML, CSS, and JavaScript", "Worked in a team using the Agile methodology for constant collaboration and continuous improvement at every stage of the project"]
    }
];