import React from 'react'
import './certificates.css'

const Certificates = () => {
    return (
        <section id='certificates'>

        </section>
    )
}

export default Certificates